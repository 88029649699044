<template>
  <div>
    <div class="recognize">
      <div class="recognize-container">
        <img src="@/assets/img/invoiceCenter/Close@2x.png" @click="handleRecognizeDelete(invoice.id)" />
        <div :class="['image-frame', invoice.status == 'SUCCESS' ? 'success' : 'failure']">
          <div style="position: absolute; color: #ffffff; margin-top: 120px">
            <div>发票号码：{{ invoice.invoiceNo }}</div>
            <div>发票代码：{{ invoice.invoiceCode }}</div>
            <div>数电票号：{{ invoice.eInvoiceNo }}</div>
          </div>
          <el-image :src="invoice.recognizeImage" />
          <div class="image-mask">
            <span>{{ handleValueToLabel('InvoiceRecognizedStatus', invoice.status) }}</span>
            <div>
              <el-button type="primary" @click="openPreview">预览</el-button>
              <el-button type="primary" @click="handleRecognizeEdit">编辑</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="recognize-bottom">
        <!--<div style="float: left;">{{ invoice.imageChineseKey }} :</div>-->
        <div style="float: left; color: #02bf02">{{ invoice.invoiceSeal == true ? '有章' : '无章' }}</div>
        <div style="float: right; margin-left: 140px">第{{ invoice.batchIndex }}张</div>
      </div>
    </div>
    <!-- 影像展示弹窗 -->
    <el-dialog title="" modal :close-on-click-modal="false" :visible.sync="previewVisible">
      <div>
        <div class="content">
          <div style="display: inline-block; color: black; font-size: 22px; margin-bottom: 20px">
            <!--<span>{{ invoice.imageChineseKey }}</span>-->
            <span style="margin-left: 50px">{{ invoice.invoiceSeal == true ? '有章' : '无章' }}</span>
            <span style="margin-left: 50px">发票号码：{{ invoice.invoiceNo }}</span>
            <span style="margin-left: 50px">发票代码：{{ invoice.invoiceCode }}</span>
            <span style="margin-left: 50px">数电票号：{{ invoice.eInvoiceNo }}</span>
          </div>
          <el-image :src="invoice.recognizeImage" :preview-src-list="previewImgList"> </el-image>
        </div>
      </div>
    </el-dialog>

    <!-- 发票编辑弹窗 -->
    <el-dialog append-to-body fullscreen title="编辑" :visible.sync="updateVisible" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleInvoiceUpdateCancel">
      <cusMessage />
      <div class="photo_box" v-show="isShowPhoto">
        <DragScaleImg :src="imgPre" v-if="imgPre" />
      </div>
      <div class="tabs_box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="主要信息" name="master" class="invoice-update">
            <el-form ref="invoiceUpdate" :model="invoiceUpdate" :rules="rules" label-position="top">
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="发票类型" prop="invoiceType">
                    <el-select v-model="invoiceUpdate.invoiceType" placeholder="请选择发票类型">
                      <el-option v-for="item in invoiceTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input v-model="invoiceUpdate.invoiceNo" placeholder="请输入发票号码" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="发票代码" prop="invoiceCode">
                    <el-input v-model="invoiceUpdate.invoiceCode" placeholder="请输入发票代码" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="数电票号" prop="eInvoiceNo">
                    <el-input v-model="invoiceUpdate.eInvoiceNo" placeholder="请输入数电票号" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="开票日期" placeholder="请选择开票日期">
                    <el-date-picker v-model="invoiceUpdate.invoiceTime" type="date" value-format="yyyy-MM-dd" :picker-options="pickerDisabled" placeholder="请选择时间"> </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="未税金额" prop="amount">
                    <el-input v-model="invoiceUpdate.amount" placeholder="请输入未税金额" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item prop="isExistList" label="包含清单">
                    <el-select v-model="invoiceUpdate.isExistList">
                      <el-option v-for="item in isExistListArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="hasCheckCode && !isInvoiceList">
                  <el-form-item label="校验码后6位" prop="checkCode">
                    <el-input v-model="invoiceUpdate.checkCode" placeholder="请输入校验码后6位" maxlength="6" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="imageChineseKey" label="发票联次">
                    <el-radio v-model="invoiceUpdate.imageChineseKey" label="发票联">发票联</el-radio>
                    <el-radio v-model="invoiceUpdate.imageChineseKey" label="抵扣联">抵扣联</el-radio>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input v-model="invoiceUpdate.invoiceNo" placeholder="请输入发票号码" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="总清单数" prop="pageSize">
                    <el-input v-model="invoiceUpdate.pageSize" placeholder="请输入总清单数" maxlength="8" />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="当前页码" prop="pageIndex">
                    <el-input v-model="invoiceUpdate.pageIndex" placeholder="请输入当前页码" maxlength="8" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="审核信息" name="shenhe" class="invoice-update">
            <el-form label-position="top">
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="开票人" prop="payer">
                    <el-input v-model="invoiceUpdate.payer" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否盖章" prop="invoiceSeal">
                    <el-select v-model="invoiceUpdate.invoiceSeal">
                      <el-option v-for="item in isInvoiceSealArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印代码" prop="printedCode">
                    <el-input v-model="invoiceUpdate.printedCode" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="复核人" prop="auditor">
                    <el-input v-model="invoiceUpdate.auditor" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="印刷代码" prop="printedBrushCode">
                    <el-input v-model="invoiceUpdate.printedBrushCode" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印号码" prop="printedNo">
                    <el-input v-model="invoiceUpdate.printedNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="收款人" prop="payee">
                    <el-input v-model="invoiceUpdate.payee" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="印刷号码" prop="printedBrushNo">
                    <el-input v-model="invoiceUpdate.printedBrushNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票信息" name="kaipiao" class="invoice-update">
            <el-form label-position="top">
              <h5 class="kp_title">购方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="buyerName">
                    <el-input v-model="invoiceUpdate.buyerName" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="buyerTaxNo">
                    <el-input v-model="invoiceUpdate.buyerTaxNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="buyerAddressTel">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.buyerAddressTel" placement="top-start"> <el-input v-model="invoiceUpdate.buyerAddressTel" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="buyerBankAccount">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.buyerBankAccount" placement="top-start"><el-input v-model="invoiceUpdate.buyerBankAccount" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
              <h5 class="kp_title" style="margin-top: 40px">销方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="sellerName">
                    <el-input v-model="invoiceUpdate.sellerName" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="sellerTaxNo">
                    <el-input v-model="invoiceUpdate.sellerTaxNo" placeholder="" maxlength="12" />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="sellerAddressTel">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.sellerAddressTel" placement="top-start"><el-input v-model="invoiceUpdate.sellerAddressTel" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="sellerBankAccount">
                    <el-tooltip class="item" effect="dark" :content="invoiceUpdate.buyerBankAccount" placement="top-start"><el-input v-model="invoiceUpdate.buyerBankAccount" placeholder="" maxlength="12" /></el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票明细" name="kpmx" class="invoice-update">
            <InvoiceOpenGoodsList class="incvoice_open_goods_list" ref="invoiceGoodsList" :goods-list="goodsList" biz-type="manual" @handleFocusTd="handleFocusTd" @handleInsertMerchandise="handleInsertMerchandise" @handleDiscount="handleDiscount" @changeIndex="changeIndex" @dataSelect="handleCurrentGoods" @handleShowGoods="handleShowGoods" @nameBlur="handleMerchandiseNameBlur" @reviewInvoice="handleReviewInvoice" @handleDeleteItems="handleDeleteItems" />
          </el-tab-pane>
        </el-tabs>
        <div class="show_photo_btn" style="position: absolute; top: 8px; right: 0">显示影像&nbsp;&nbsp;<el-switch v-model="isShowPhoto"> </el-switch></div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleInvoiceUpdateCancel">取消</el-button>
        <el-button type="primary" @click="handleRecognizeEditSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!-- 选择商品 -->
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible" @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>

    <other-invoice-detail :invoiceUpdate="invoiceUpdate" :show.sync="otherVisible" @cancel="handleOtherImageDetailCancel" @save="handleOtherImageDetailSave"></other-invoice-detail>
  </div>
</template>

<script>
import { DataUtil } from '@/util/dataUtil';
import RegexLib from '@/assets/js/regex-lib';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { editImageInvoice, updateImageInvoice, getInvoiceRecognizeImage } from '@/service/invoice-center';
import { RECOGNIZE_INVOICE_TYPES } from '@/content/invoice.js';
import { dateOption } from '@/util/mixins';
import { RECOGNIZE_INVOICE_SEAL } from '@/content/invoice';
import OtherInvoiceDetail from '@/components/invoice/OtherInvoiceDetail';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import InvoiceOpenGoodsList from '@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenGoodsList.vue';
import MerchandiseListDialog from '@/views/invoice-open/invoice-issue/components/MerchandiseListDialog.vue';
import cusMessage from '@/components/message/Message';
import DragScaleImg from '@/components/DragScaleImg';
export default {
  name: 'InvoiceRecognize',
  directives: { Clickoutside },
  watch: {
    updateVisible(val) {
      if (!val) {
        this.imgPre = '';
      }
    }
  },
  props: {
    invoice: {
      type: Object
    },
    images: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number
    }
  },
  mixins: [dateOption],
  components: { ElImageViewer, OtherInvoiceDetail, InvoiceOpenGoodsList, MerchandiseListDialog, cusMessage, DragScaleImg },
  data() {
    return {
      imgPre: '',
      invoiceImages: [],
      isInvoiceSealArr: [
        { value: true, label: '已盖章' },
        { value: false, label: '未盖章' }
      ],
      currentGoodsIndex: 0,
      dialogGoodsListVisible: false,
      goodsList: [],

      /* 编辑数据区 */
      otherVisible: false,
      updateVisible: false,
      isShowPhoto: false,
      updateListVisible: false,
      previewVisible: false,
      invoiceTypes: RECOGNIZE_INVOICE_TYPES,
      invoiceUpdate: {
        id: '',
        invoiceType: '',
        invoiceCode: '',
        invoiceNo: '',
        checkCode: '',
        invoiceTime: '',
        channel: '',
        pageSize: '',
        pageIndex: '',
        isExistList: '',
        imageChineseKey: '发票联',
        invoiceLabel: '',
        payee: '',
        auditor: '',
        payer: '',
        invoiceSeal: '',
        printedBrushCode: '',
        printedCode: '',
        printedBrushNo: '',
        printedNo: '',
        rider: '',
        train21No: ''
      },
      rules: {
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' },
          { min: 10, max: 12, message: '请输入10位或12位的发票代码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceCode, message: '请输入正确的发票代码' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' },
          { min: 8, max: 8, message: '请输入8位发票号码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceNo, message: '请输入正确的发票号码' }
        ],
        amount: [
          { required: true, message: '请输入发票金额', trigger: 'blur' },
          {
            pattern: RegexLib.Amount,
            message: '请输入正确的金额'
          }
        ],
        checkCode: [
          { required: true, message: '请输入校验码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入校验码后六位', trigger: 'blur' },
          {
            pattern: RegexLib.InvoiceVerifyCode,
            message: '请输入正确的校验码'
          }
        ],
        pageSize: [
          { required: true, message: '请输入总页数', trigger: 'blur' },
          { min: 1, message: '请输入至少1位总页数', trigger: 'blur' },
          { pattern: RegexLib.PageSize, message: '请输入正确的总页数' }
        ],
        pageIndex: [
          { required: true, message: '请输入当前页数', trigger: 'blur' },
          { min: 1, message: '请输入至少1位当前页数', trigger: 'blur' },
          { pattern: RegexLib.PageIndex, message: '请输入正确的当前页数' }
        ],
        isExistList: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        }
      },
      /* 预览数据区 */
      invoiceImg: {
        visible: false,
        url: ''
      },
      payVoucherDialog: false,
      previewImgList: [],
      isExistListArr: [
        { value: false, label: '否' },
        { value: true, label: '是' }
      ],
      activeName: 'master',
      invoiceSeals: RECOGNIZE_INVOICE_SEAL
    };
  },
  computed: {
    hasCheckCode() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType == 'SpecInvoice' || invoiceType == 'VehInvoice' ? false : true;
    },
    isInvoiceList() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType === 'VatInvoiceList';
    }
  },
  methods: {
    /* 发票影像 */
    async handleInvoiceRecognizeImage(invoice) {
      // 识别影像请求
      const { success, data } = await getInvoiceRecognizeImage(invoice.invoiceFileId);
      if (!success) {
        this.toast('识别影像获取失败！', 'error');
        return;
      }
      // 识别影像结果处理
      this.imgPre = data;
      this.invoiceImages.push(data);
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
      this.editVisible = !!this.merchandise.merchandiseName;
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == '2';
      this.discountStatus ? (this.deletedDiscountVisible = true) : (this.insertDiscountVisible = true);
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 商品选中
    handleCurrentGoods(row) {
      console.log(row, '------handleCurrentGoods------');
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent;
        goods['taxRate'] = 0;
      } else {
        goods['taxRate'] = Number(row.taxRate);
        goods['taxRateModel'] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById('input-' + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      // this.invoicePrice = invoicePrice;
    },
    handleDeleteItems(index) {
      // this.deleteIndex = index;
    },
    handleImageClose() {
      // this.previewVisible = false
    },
    handleOtherImageDetailSave() {
      this.otherVisible = false;
      this.invoiceUpdate = {};
    },
    handleOtherImageDetailCancel() {
      this.otherVisible = false;
    },
    // 点击打开图片预览
    openPreview() {
      console.log('image list:', this.images);
      this.images.forEach((image) => {
        this.previewImgList.push(image);
      });
      this.previewVisible = true;
    },
    /* 识别发票删除 */
    handleRecognizeDelete(id) {
      this.$emit('invoice-delete', id);
    },
    /* 识别发票编辑 */
    handleRecognizeEdit() {
      editImageInvoice(this.id).then((res) => {
        if (res.success) {
          let invoice = res.data;
          if (invoice.invoiceType == 'TrainInvoice' || invoice.invoiceType == 'TaxiInvoice' || invoice.invoiceType == 'TollInvoice' || invoice.invoiceType == 'PlaneInvoice' || invoice.invoiceType == 'GeneralMachineInvoice' || invoice.invoiceType == 'TollInvoice' || invoice.invoiceType == 'PassengerInvoice' || invoice.invoiceType == 'QuotaInvoice') {
            this.otherVisible = true;
          } else {
            this.updateVisible = true;
          }

          if (invoice.invoiceJson) {
            const tmpInvoiceJson = JSON.parse(invoice.invoiceJson);
            console.log(tmpInvoiceJson);
            if (tmpInvoiceJson.details && tmpInvoiceJson.details.length) {
              this.goodsList = tmpInvoiceJson.details.map((item) => {
                return {
                  ...item,
                  merchandiseName: item.goodName,
                  specification: item.general,
                  unit: item.specifications,
                  quantity: item.goodNum,
                  price: item.goodPrice,
                  sumAmount: item.amount,
                  taxRateModel: item.taxRate
                };
              });
            }
          }

          this.invoiceUpdate = invoice;
          this.invoiceUpdate.checkCode = invoice.checkCode.substring(invoice.checkCode.length - 6);
          this.handleInvoiceRecognizeImage(invoice);
        }
      });
    },
    /* 识别发票编辑 */
    handleRecognizeEditSubmit() {
      this.$refs.invoiceUpdate.validate((valid) => {
        if (valid) {
          let updInvoice = this.invoiceUpdate;
          this.goodsList = this.goodsList.map((item) => {
            return {
              ...item,
              goodName: item.merchandiseName,
              general: item.specification,
              specifications: item.unit,
              goodNum: item.quantity,
              goodPrice: item.price,
              amount: item.sumAmount
            };
          });

          const tmpParams = {
            ...updInvoice,
            invoiceJson: { details: this.goodsList }
          };

          updateImageInvoice(tmpParams).then((res) => {
            if (res.success) {
              if (res.data) {
                this.updateVisible = false;
                this.invoice.status = 'SUCCESS';
                this.invoice.invoiceType = updInvoice.invoiceType;
                this.invoice.invoiceCode = updInvoice.invoiceCode;
                this.invoice.invoiceNo = updInvoice.invoiceNo;
                this.invoice.amount = updInvoice.amount;
                this.invoice.invoiceTime = updInvoice.invoiceTime;
                this.invoice.pageSize = updInvoice.pageSize;
                this.invoice.pageIndex = updInvoice.pageIndex;
                this.invoice.checkCode = updInvoice.checkCode;
                this.toast('发票编辑成功', 'success');
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    /* 发票修改取消 */
    handleInvoiceUpdateCancel() {
      this.$refs.invoiceUpdate.resetFields();
      this.updateVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';
.tabs_box {
  position: relative;
}
.recognize {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .recognize-container {
    position: relative;

    img {
      width: 20px;
      height: 20px;
      margin: 6px 0px 0px 236px;
      position: absolute;
      z-index: 1;
    }

    .image-frame {
      width: 260px;
      height: 160px;
      z-index: 1;

      .el-image {
        width: 260px;
        height: 160px;
      }

      .image-mask {
        display: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 1);

        .el-image {
          opacity: 0.5;
        }

        .image-mask {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          z-index: 1;
          margin-top: -120px;

          span {
            width: 120px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 22px;
            margin-bottom: 16px;
            text-align: center;
          }
        }
      }
    }

    .success {
      border: 2px solid #02bf02;
    }

    .failure {
      border: 2px solid #f5222d;
    }
  }

  .recognize-bottom {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    display: inline-block;
  }
}

.invoice-update {
  margin: 24px;

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 影像图片容器 */
//.invoice-img {
//  position: absolute;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  background-color: rgba(0, 0, 0, 0.5);
//  z-index: 5;
//
//  .content {
//    width: 800px;
//    height: 500px;
//    text-align: center;
//    margin-bottom: 20px;
//  }
//
//  .el-image {
//    width: 800px;
//    height: 400px;
//  }
//}
.kp_title {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.incvoice_open_goods_list {
  table {
    width: 100%;
  }
}
</style>
